import { AuthResultType, AuthService, WorkflowUser } from '@digitalworkflow/dwloginclient'
import { AdminPortalLayout, MenuItem, ProfileMenuItem } from '@digitalworkflow/dwreactcommon'
import '@digitalworkflow/dwreactcommon/dist/index.css'
import '@digitalworkflow/sbpcommon/dist/index.css'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import './assets/scss/index.scss'
import { reactPluginAppInsights } from './AzureInsights'
import BuildingSearch from './pages/BuildingSearch'
import LookupManager from './pages/LookupManager'
import PageLoader from './pages/PageLoader'
import SurveyLoader from './pages/SurveyLoader'
import ThemeLoader from './pages/ThemeLoader'

import logoSmImageBgis from './assets/images/bgis-logo-sm.png'
import logoImageBgis from './assets/images/bgis-logo.png'

import logoSmImageRps from './assets/images/io-logo-sm.png'
import logoImageRps from './assets/images/io-logo.png'

import IdleWatch from './components/IdleWatch'

import { ToastContainer } from 'react-toastify'

import { PSLHelper } from '@digitalworkflow/dwtranslateclient'
import { PSL } from '@digitalworkflow/psl_web'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Modal, ModalBody } from 'reactstrap'
import packageInfo from '../package.json'
import ForceUpdate from './components/ForceUpdaate/ForceUpdate'
import { TaskListConnectionHelperProps } from './components/PageEngine/TaskList/Helper/TaskListConnectionProps'
import { useTaskListConnectionHelper } from './components/PageEngine/TaskList/Helper/useTaskListConnectionHelper'
import TaskManager from './components/TaskManager/TaskManager'
import TestAccounts from './components/TestAccounts/TestAccounts'
import TraceFeature from './components/TraceFeature'
import {
  getLoginPortalID,
  menuItems as initialMenuItems,
  keyMap,
  profileMenuItems,
  autoCloseMenuText,
  redirectToLogin
} from './constants'
import { useAuth } from './context/AuthContext'
import { MenuProvider, useMenu } from './context/MenuContext'
import ComingSoon from './pages/ComingSoon'
import ForceLogout from './pages/ForceLogout/ForceLogout'
import Reload from './pages/Reload'
import SetToken from './pages/SetToken'
import { updateSearchTerm } from './redux/slices/settingsSlice'
import { AppDispatch, RootState } from './redux/types'
import { Portal } from './types/Portal'
import { checkAdminDeveloper } from './utils/checkAdminDeveloper'
import { GlobalHashHelper } from './utils/HashHelper'
import { LocalSettings } from './utils/LocalSettings'
import { clearDatabase } from './utils/syncDatabase'

const authService = AuthService.instance()
let globalTheme: string = 'bgis'

const GuestRouteRedirect = () => {
  // Capture the internal URL
  const internalUrl = window.location.pathname

  if (internalUrl !== '/reload') {
    // Store the internal URL in local storage
    LocalSettings.setPortalRedirectUrl(internalUrl)
  }

  window.location.href =
    internalUrl === '/reload'
      ? `${PSLHelper.LoginPortalUrl()}/logout/${getLoginPortalID()}?guest=1&${
          window.location.host.match(/local/) ? 'return=local' : ''
        }`
      : `${PSLHelper.LoginPortalUrl()}/login/${getLoginPortalID()}?guest=1&${
          window.location.host.match(/local/) ? 'return=local' : ''
        }`

  return null
}

// Create a dedicated component with proper typing and error handling
interface TaskListConnectionsProps {
  dispatch: AppDispatch
  isDatabaseSynced: boolean
}

const TaskListConnections: React.FC<TaskListConnectionsProps> = ({ dispatch }) => {
  const currentSearchTerm = useSelector((state: RootState) => state.settings.currentSearchString)

  // Use an effect to log connection status for monitoring
  useEffect(() => {
    console.log('TaskList connections initialized')
    return () => {
      console.log('TaskList connections cleanup')
    }
  }, [])

  try {
    return (
      <>
        <TaskListConnection dispatch={dispatch} step_option='Todo' step_text='Task List' sname='todo' />
        <TaskListConnection dispatch={dispatch} step_option='Dashboard' step_text='My Dashboard' sname='dashboard' />
        {currentSearchTerm && (
          <TaskListConnection dispatch={dispatch} step_option='Search' step_text={currentSearchTerm} sname='search' />
        )}
      </>
    )
  } catch (error) {
    console.error('Error in TaskListConnections:', error)
    // Could dispatch an error action here
    return null
  }
}

// Separate component for individual connections
const TaskListConnection: React.FC<TaskListConnectionHelperProps> = (props) => {
  useTaskListConnectionHelper(props)
  return null
}

const AppContent = () => {
  const { isAuthenticated, user } = useAuth()
  const [portal, setPortal] = useState<Portal>()
  const [showTraceFeauture, setShowTraceFeauture] = useState<boolean>(false)
  const [adminDeveloper, setAdminDeveloper] = useState<boolean>(false)
  const { menuItems } = useMenu()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [countdown, setCountdown] = useState<number>(15)
  const navigate = useNavigate()
  const [isCheckedAutoCloseMenu, setIsCheckedAutoCloseMenu] = useState<boolean>(LocalSettings.autoCloseMenu)

  const [actualNavMenuItems, setActualNavMenuItems] = useState<MenuItem[]>([])

  const dispatch = useDispatch()
  const isDatabaseSynced = useSelector((state: RootState) => state.settings.isDatabaseSynced)
  const authTokenInterval = useRef<NodeJS.Timeout | null>(null)
  const isAuthenticating = useRef<boolean>(false) // Prevent overlapping API calls

  // Function to Authenticate token
  const authenticateToken = async () => {
    if (isAuthenticating.current) return // Prevent multiple concurrent calls
    isAuthenticating.current = true

    try {
      const authToken = LocalSettings.getPortalAuthToken()
      if (authToken) {
        const res: AuthResultType = await authService.authUserFromToken(authToken)
        console.log('App.tsx AuthFromToken Result=', res)

        if (res && res.is_error === true) {
          localStorage.clear()
          const isDataBaseCleared = await clearDatabase()
          if (isDataBaseCleared) console.log('App.tsx Database Cleared')
          redirectToLogin()
        }
      }
    } catch (error) {
      console.error('App.tsx Token authentication failed:', error)
    } finally {
      isAuthenticating.current = false
    }
  }
  useEffect(() => {
    let result = checkAdminDeveloper()
    if (document.location.hostname.match(/localhost/)) result = true
    if (PSL.ProdRules()) result = false
    setAdminDeveloper(result)
  }, [])

  useEffect(() => {
    if (user.portals_enabled && user.portals_enabled?.length > 0) {
      getPortalDetails(getLoginPortalID())
    }
  }, [user.portals_enabled])

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      const route = keyMap[event.key]
      if (route) {
        event.preventDefault()
        navigate(route)
      }
    }

    window.addEventListener('popstate', (event) => {
      console.log('Window Event:', event)
    })
    window.addEventListener('keydown', handleKeyPress)
    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [])

  useEffect(() => {
    if (user.username !== '') {
      const current = WorkflowUser.GetCurrentUser()
      if (current.hasWorkgroup('SB')) {
        // Insert a new menu item for Smart Buildings
        const smartBuildingMenuItem = {
          item_type: 'Link',
          title_en: 'SBP Management',
          icon: 'fa-light fa-building',
          view_groups: 'SB',
          route: '/page/digital_forms_sb',
          children: []
        }
        const newMenuItems = [...menuItems.slice(0, 4), smartBuildingMenuItem, ...menuItems.slice(4)] //, smartBuildingMenuItem, ...menuItems.slice(4)]
        setActualNavMenuItems(newMenuItems)
        return
      }
    }

    setActualNavMenuItems(menuItems)
  }, [user, menuItems])

  // multiple tabs will communicate through this channel, if a user is switching, it will show a modal to reload page
  useEffect(() => {
    const switchUserChannel = new BroadcastChannel('switchUserChannel')
    switchUserChannel.onmessage = (event) => {
      if (event.data.isUserSwitching) {
        const sessionItem = sessionStorage.getItem('isUserSwitching')
        if (!sessionItem) {
          setShowModal(true)
        }
      }
    }

    return () => {
      switchUserChannel.close()
    }
  }, [])

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (showModal) {
      timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            window.location.reload()
            return 0
          }
          return prev - 1
        })
      }, 1000)
    }
    return () => clearInterval(timer)
  }, [showModal])

  useEffect(() => {
    authenticateToken() // Call it immediately on mount

    // Set interval for authenticating token every 10 minutes
    authTokenInterval.current = setInterval(() => {
      authenticateToken()
    }, 1000 * 60 * 10)

    // Cleanup on unmount
    return () => {
      if (authTokenInterval.current) {
        clearInterval(authTokenInterval.current)
        console.log('App.tsx Cleared refresh token interval')
      }
    }
  }, [])

  const handleReload = () => {
    window.location.reload()
  }

  const handleCancelTimer = () => {
    setCountdown(10)
    setShowModal(false)
    window.location.reload()
  }

  const getPortalDetails = (id: string) => {
    try {
      const portalInfo = user.portals_enabled?.find((item) => item.login_portal_id === id)
      if (portalInfo) {
        const portalData: Portal = {
          buttons: portalInfo.buttons,
          login_portal_id: portalInfo.login_portal_id,
          portal_logo: portalInfo.portal_logo,
          portal_name: portalInfo.portal_name,
          redirect_location_after_success: portalInfo.redirect_location_after_success,
          portal_theme: portalInfo.portal_theme,
          portal_logo_full: ''
        }

        if (GlobalHashHelper.hasOption('rps')) {
          handleTheme('io')
        } else {
          if (LocalSettings.getTheme() !== 'io') handleTheme(portalData?.portal_theme ?? '')
        }

        setPortal(portalData)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleTheme = (theme: string) => {
    LocalSettings.setTheme(theme)
    globalTheme = theme
    document.body.classList.add('theme-' + (theme !== null && theme !== undefined ? theme : 'bgis'))
  }

  const logo = useMemo((): string => {
    let logoIcon = logoImageBgis
    if (globalTheme === 'io') {
      logoIcon = logoImageRps
      return logoIcon
    }
    if (portal?.portal_logo) {
      logoIcon = `${PSLHelper.LoginPortalUrl()}/api/v1/portals/logo/${portal.portal_logo}`
    }
    return logoIcon
  }, [portal])

  const env = useMemo((): string => PSL.GetEnvironment(), [])

  const handleSearch = (str: string) => {
    str = str.replace('_task', '')
    dispatch(updateSearchTerm(str))
    navigate(`/page/search/${str}`)
  }

  const getAutoCloseMenuIcon = () => {
    const isChecked = LocalSettings.autoCloseMenu
    return isChecked ? 'fa-regular fa-square-check' : 'fa-regular fa-square'
  }

  const setAutoCloseMenuIcon = () => {
    const isChecked = LocalSettings.autoCloseMenu
    LocalSettings.autoCloseMenu = !isChecked
    setIsCheckedAutoCloseMenu(!isChecked)
  }

  return (
    <AppInsightsContext.Provider value={reactPluginAppInsights}>
      {isDatabaseSynced && <TaskListConnections dispatch={dispatch} isDatabaseSynced={isDatabaseSynced} />}
      <Modal isOpen={showModal} toggle={handleCancelTimer} backdrop='static' centered>
        <ModalBody>
          Your account has been updated. Please refresh the page to sync your account and ensure everything works
          correctly.
          <div className='w-100 d-flex flex-row justify-content-center'>
            <Button color='primary' onClick={handleReload}>
              OK {countdown}
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <TraceFeature isOpen={showTraceFeauture} setIsOpen={setShowTraceFeauture} />
      <IdleWatch />
      <Routes>
        <Route path='/' element={<SetToken />} />
        <Route path='/logout' element={<ForceLogout />} />
        <Route path='/MK/:magicKey/*' element={<SurveyLoader />} />
        <Route path='/io/*' element={<ThemeLoader />} />
        <Route path='/manage/*' element={<TaskManager />} />
        <Route
          path='/'
          element={
            isAuthenticated
              ? React.createElement(AdminPortalLayout, {
                  menuItems: actualNavMenuItems,
                  profileMenuItems: profileMenuItems.map((item: ProfileMenuItem) => {
                    if (item.text_en === autoCloseMenuText) {
                      return {
                        ...item,
                        icon: getAutoCloseMenuIcon(),
                        action: () => setAutoCloseMenuIcon()
                      }
                    }
                    return item
                  }),
                  logoImage: logo,
                  logoSmImage: globalTheme === 'io' ? logoSmImageRps : logoSmImageBgis,
                  username: user?.email,
                  hideTranslate: true,
                  showWorldClock: false,
                  versionNumber: packageInfo.version,
                  env: env,
                  showGearIcon: adminDeveloper,
                  handleSetting: () => setShowTraceFeauture((prev) => !prev),
                  handleSearch: handleSearch,
                  autoCloseMenu: isCheckedAutoCloseMenu
                })
              : React.createElement(GuestRouteRedirect, {})
          }
        >
          <Route path='building-search' element={<BuildingSearch />} />
          <Route path='dashboard' element={<ComingSoon />} />
          <Route path='task-inbox' element={<ComingSoon />} />
          <Route path='force-update' element={<ForceUpdate />} />
          <Route path='test-accounts' element={<TestAccounts />} />
          <Route path='lookup-manager' element={<LookupManager />} />
          <Route path='reload' element={<Reload />} />
          <Route path='*' element={<PageLoader />} />
        </Route>
      </Routes>
      <ToastContainer
        position='bottom-right'
        progressStyle={{ height: '6px' }}
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
    </AppInsightsContext.Provider>
  )
}

const App = () => {
  return (
    <MenuProvider initialMenuItems={initialMenuItems}>
      <AppContent />
    </MenuProvider>
  )
}

export default App
