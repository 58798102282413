import { useState, useEffect } from 'react'
import {
  clearDatabase,
  deleteDatabase,
  getCollectionsCount,
  syncDatabase,
  TableRowCount
} from '../../utils/syncDatabase'

interface ForceUpdateData {
  syncing: boolean
  width: number
  message: string
  afterdb: TableRowCount
  beforedb: TableRowCount
  showDiagnostics: boolean
  setShowDiagnostics: (show: boolean) => void
  forceUpdate: (taskId?: string) => Promise<void>
}

export const useForceUpdateData = (): ForceUpdateData => {
  const [syncing, setSyncing] = useState(false)
  const [width, setWidth] = useState(0)
  const [message, setMessage] = useState('Database and Configuration Update In Progress')
  const [afterdb, setAfterDb] = useState<TableRowCount>({})
  const [beforedb, setBeforeDb] = useState<TableRowCount>({})
  const [showDiagnostics, setShowDiagnostics] = useState<boolean>(false)

  const forceUpdate = async (taskId?: string) => {
    setSyncing(true)
    setMessage('Database and Configuration Update In Progress')
    if (!taskId) {
      const result_before: TableRowCount = await getCollectionsCount()
      setBeforeDb(result_before)
      await clearDatabase()
    } else {
      await deleteDatabase()
    }
    await syncDatabase(setWidth, setSyncing, taskId)
  }

  useEffect(() => {
    if (!syncing) {
      setMessage('Database and Configuration Updated Successfully')
      const fetchData = async () => {
        const result: TableRowCount = await getCollectionsCount()
        setAfterDb(result)
      }
      fetchData()
    }
  }, [syncing])

  console.log('useForceUpdateData=> ', ' --syncing: ', syncing, ' --width: ', width)
  return {
    syncing,
    width,
    message,
    afterdb,
    beforedb,
    showDiagnostics,
    setShowDiagnostics,
    forceUpdate
  }
}
