import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { ColumnState } from 'ag-grid-community'

export type TaskViewSettingsSliceType = 'todo' | 'dashboard' | 'search'

type ColumnGroupState = {
  groupId: string
  open: boolean
}
type FilterModel = {
  [key: string]: any
}

type ColumnResized = {
  field: string
  width: number
}

export type TaskViewSettings = {
  columnState: ColumnState[]
  filterModel: FilterModel
  rowGroupColumns: string[]
  columnGroupState: ColumnGroupState[]
  columnResized: ColumnResized[]
  pivotMode: boolean
  expandOrCollapseAll: string
}

type TaskViewSettingsSliceState = {
  [project: string]: {
    settings: TaskViewSettings
  }
}

type TaskViewSettingsState = {
  [sname in TaskViewSettingsSliceType]: TaskViewSettingsSliceState
}

export const initialTaskViewSettings: TaskViewSettings = {
  columnState: [],
  filterModel: {},
  rowGroupColumns: ['dash_category'],
  columnGroupState: [],
  columnResized: [],
  pivotMode: false,
  expandOrCollapseAll: ''
}

const initialState: TaskViewSettingsState = {
  todo: {
    default: {
      settings: initialTaskViewSettings
    }
  },
  dashboard: {
    default: {
      settings: initialTaskViewSettings
    }
  },
  search: {
    default: {
      settings: initialTaskViewSettings
    }
  }
}

const taskViewSettingsSlice = createSlice({
  name: 'taskViewSettings',
  initialState,
  reducers: {
    resetTaskViewSettings: () => {
      return initialState
    },

    resetColumnFilterState: (state, action: PayloadAction<{ sname: TaskViewSettingsSliceType; project?: string }>) => {
      const { sname, project = 'default' } = action.payload

      if (state[sname][project]) {
        state[sname][project] = { settings: initialTaskViewSettings }
      }
    },

    updateColumnState: (
      state,
      action: PayloadAction<{ sname: TaskViewSettingsSliceType; columnState: ColumnState[]; project?: string }>
    ) => {
      const { sname, project = 'default', columnState } = action.payload

      if (!state[sname][project]) {
        state[sname][project] = { settings: initialTaskViewSettings }
      }

      state[sname][project].settings = {
        ...state[sname][project].settings,
        columnState
      }
    },

    updateColumnGroupState: (
      state,
      action: PayloadAction<{
        sname: TaskViewSettingsSliceType
        columnGroupState: ColumnGroupState[]
        project?: string
      }>
    ) => {
      const { sname, project = 'default', columnGroupState } = action.payload

      if (!state[sname][project]) {
        state[sname][project] = { settings: initialTaskViewSettings }
      }

      state[sname][project].settings = {
        ...state[sname][project].settings,
        columnGroupState
      }
    },

    updateRowGroupColumns: (
      state,
      action: PayloadAction<{ sname: TaskViewSettingsSliceType; rowGroupColumns: any; project?: string }>
    ) => {
      const { sname, project = 'default', rowGroupColumns } = action.payload

      if (!state[sname][project]) {
        state[sname][project] = { settings: initialTaskViewSettings }
      }
      state[sname][project].settings = {
        ...state[sname][project].settings,
        rowGroupColumns
      }
    },

    updateFilterModel: (
      state,
      action: PayloadAction<{ sname: TaskViewSettingsSliceType; filterModel: FilterModel; project?: string }>
    ) => {
      const { sname, project = 'default', filterModel } = action.payload
      if (!state[sname][project]) {
        state[sname][project] = { settings: initialTaskViewSettings }
      }
      state[sname][project].settings = {
        ...state[sname][project].settings,
        filterModel
      }
    },

    updateColumnResized: (
      state,
      action: PayloadAction<{ sname: TaskViewSettingsSliceType; columnResized: ColumnResized[]; project?: string }>
    ) => {
      const { sname, project = 'default', columnResized } = action.payload
      if (!state[sname][project]) {
        state[sname][project] = { settings: initialTaskViewSettings }
      }

      const columns = [...state[sname][project].settings.columnResized, ...columnResized]
      /* remove duplicate columns */
      const unqiueColumns = [...new Map(columns.map((item) => [item.field, item])).values()]

      state[sname][project].settings = {
        ...state[sname][project].settings,
        columnResized: unqiueColumns
      }
    },

    updatePivotMode: (
      state,
      action: PayloadAction<{ sname: TaskViewSettingsSliceType; pivotMode: boolean; project?: string }>
    ) => {
      const { sname, project = 'default', pivotMode } = action.payload
      if (!state[sname][project]) {
        state[sname][project] = { settings: initialTaskViewSettings }
      }
      state[sname][project].settings = {
        ...state[sname][project].settings,
        pivotMode
      }
    },

    updateExpandOrCollapseAll: (
      state,
      action: PayloadAction<{
        sname: TaskViewSettingsSliceType
        expandOrCollapseAll: string
        project?: string
      }>
    ) => {
      const { sname, project = 'default', expandOrCollapseAll } = action.payload
      if (!state[sname][project]) {
        state[sname][project] = { settings: initialTaskViewSettings }
      }
      state[sname][project].settings = {
        ...state[sname][project].settings,
        expandOrCollapseAll
      }
    }
  }
})

const taskViewSettingsPersistConfig = {
  key: 'taskViewSettings',
  storage,
  whitelist: ['todo', 'dashboard', 'search']
}

const persistViewSettingsReducer = persistReducer(taskViewSettingsPersistConfig, taskViewSettingsSlice.reducer)

export const {
  resetTaskViewSettings,
  updateColumnState,
  updateFilterModel,
  updateRowGroupColumns,
  updateColumnGroupState,
  updateColumnResized,
  updatePivotMode,
  resetColumnFilterState,
  updateExpandOrCollapseAll
} = taskViewSettingsSlice.actions
export default persistViewSettingsReducer
