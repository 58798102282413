import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthService, AuthResultType } from '@digitalworkflow/dwloginclient'
import { useAuth } from '../../context/AuthContext'
import { LocalSettings } from '../../utils/LocalSettings'
import { getLoginPortalID, redirectToLogin } from '../../constants'
import { useDispatch } from 'react-redux'
import { setCurrentRoute, setCurrentUsername } from '../../redux/slices/settingsSlice'
import { clearDatabase } from '../../utils/syncDatabase'
import { resetTaskViewSettings } from '../../redux/slices/taskViewSettingsSlice'

const authService = AuthService.instance()

const SetToken = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { setIsAuthenticated, setUser } = useAuth()

  const dispatch = useDispatch()

  useEffect(() => {
    const init = async () => {
      const params = new URLSearchParams(location.search)
      let authToken = params.get('token')
      console.log(`Loading from http://${document.location.host}?token=` + authToken)

      if (authToken) {
        LocalSettings.setPortalAuthToken(authToken)
      } else {
        authToken = LocalSettings.getPortalAuthToken() ?? ''
      }

      AuthService.setPortalLoginId(getLoginPortalID())
      const res: AuthResultType = await authService.authUserFromToken(authToken)
      console.log('AuthFromToken Result=', res)

      if (res && res.is_error === false) {
        setIsAuthenticated(true)

        setUser({
          portals_enabled: res.data.user.portals_enabled,
          username: res.data.user.email,
          email: res.data.user.email
        })

        LocalSettings.setPortalUser(JSON.stringify(res.data.user))
        dispatch(setCurrentUsername(res.data.user))
        dispatch(setCurrentRoute(''))

        if (LocalSettings.lastLoginUserEmail !== res.data.user.email) {
          dispatch(resetTaskViewSettings())
        }

        // Retrieve the redirect URL from local storage
        const redirectUrl = LocalSettings.getPortalRedirectUrl()
        if (redirectUrl) {
          LocalSettings.removePortalRedirectUrl()
          if (redirectUrl !== '/reload') {
            console.log('Reedirect to', redirectUrl)
            return navigate(redirectUrl)
          }
        } else {
          console.log('Reedirect to Dashboard')
          return navigate('/page/dashboard')
        }
      }
      await clearDatabase()
      console.log('Reedirect to Login Portal')
      setIsAuthenticated(false)
      redirectToLogin()
    }
    init()
  }, [])

  return <div className='token-page'></div>
}

export default SetToken
