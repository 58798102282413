import { FindTaskResults, LogicEngine, LogicEngineDataDateTime, StepTaskList } from '@digitalworkflow/dwtranslateclient'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import { TaskListConnectionHelperProps } from './TaskListConnectionProps'

import {
  TaskListCounters,
  updateAllTaskList,
  updateTaskList,
  updateTaskListCounters,
  updateTaskListLoaded,
  updateTaskListStatus
} from '../../../../redux/slices/tasklistSlice'
import { TaskStatusCode } from '@digitalworkflow/dwtranslateclient/lib/Models/Task/TaskSchema'

export type TaskListConnectionHelperState = {}

export function useTaskListConnectionHelper(props: TaskListConnectionHelperProps): TaskListConnectionHelperState {
  /** Track the username that the task list and dashboard apply to */
  const [loadedUsername, setLoadedUsername] = useState<string>('')

  const showClosed = useSelector((state: RootState) => state.settings.showClosed)
  const showMuted = useSelector((state: RootState) => state.settings.showMuted)
  const showOther = useSelector((state: RootState) => state.settings.showOther)
  const showRejected = useSelector((state: RootState) => state.settings.showRejected)
  const currentRoute = useSelector((state: RootState) => state.settings.currentRoute)

  /** Watch the current user, if the user changes, we are going to need a new step */
  const currentUserName = useSelector((state: RootState) => state.settings.currentUsername)
  const currentSearchString = useSelector((state: RootState) => state.settings.currentSearchString)

  const allTaskList = useSelector((state: RootState) => state.tasks[props.sname].allTasks)

  const dispatch = useDispatch()

  const currentStep = useRef<StepTaskList | undefined>(undefined)

  const filterTaskList = (rawTaskList: FindTaskResults[]) => {
    // Create a Map to maintain the latest version of each task
    const latestTasksMap = new Map<string, FindTaskResults>()

    // Process all tasks to ensure we have the latest version of each
    rawTaskList.forEach((task) => {
      const existingTask = latestTasksMap.get(task.task_id)
      const taskUpdateDt = new LogicEngineDataDateTime(task.updated_dt)
      const existingTaskUpdateDt = new LogicEngineDataDateTime(existingTask?.updated_dt)
      if (!existingTask || taskUpdateDt > existingTaskUpdateDt) {
        latestTasksMap.set(task.task_id, task)
      }
    })

    // Get all parent tasks
    const allTasks = Array.from(latestTasksMap.values())

    const counters: TaskListCounters = {
      totalMuted: 0,
      totalRejected: 0,
      totalClosed: 0,
      totalOther: 0
    }

    // Apply active filters from Redux store
    const filteredList = allTasks.filter((task) => {
      // Filter by project type if specified in route
      if (currentRoute?.includes('type=')) {
        const taskType = currentRoute.split('type=').pop()
        if (taskType && typeof taskType === 'string' && task.project_id !== taskType) {
          return false
        }
      }

      return true
    })
    // Get the latest version of all tasks and apply filters
    const finalFilteredList = filteredList.filter((task) => {
      // Filter out subtasks based on parent task status or parent task existance (according to business logic shared by Hemali)
      if (task.parent_id) {
        const parentTask = latestTasksMap.get(task.parent_id)
        // check if the parent task is muted and showMuted is true then exclude subtask
        if (parentTask?.task_status_id === TaskStatusCode.TaskMuted && !showMuted) return false
        // If the current section is 'dashboard' and there is a parent task, exclude the subtask
        if (props.sname === 'dashboard' && parentTask) return false

        // If a parent task exists and 'showOther' is enabled, but the parent task is assigned to a different user, exclude the subtask
        if (parentTask && showOther && parentTask.auser !== currentUserName) return false
      }

      // Filter by user assignment on task inbox and search page
      if (props.sname !== 'dashboard' && task.auser !== undefined && task.auser !== '') {
        // Increase count for current user or other users
        counters.totalOther++
        if (!showOther && task.auser !== currentUserName) {
          return false // Only show other users if the flag showOther is true
        }
      }

      // Filter out muted tasks if showMuted is false
      if (task.task_status === 'Muted') {
        counters.totalMuted++
        if (!showMuted) return false
      }

      // Filter out rejected tasks if showRejected is false
      if (['Rejected', 'Cancelled'].includes(task.task_status)) {
        counters.totalRejected++
        if (!showRejected) return false
      }

      // Filter out closed tasks if showClosed is false
      if (task.task_status === 'Closed') {
        counters.totalClosed++
        if (!showClosed) return false
      }

      // Filter out tasks on dashboard based on user assignment and create by
      if (
        props.sname === 'dashboard' &&
        task.auser !== currentUserName &&
        task.auser !== '' &&
        task.create_by !== currentUserName
      ) {
        return false
      }

      return true
    })

    console.log('TaskListConnectionHelper UPdating Counters for', props.sname, counters)
    dispatch(updateTaskListCounters({ sname: props.sname, counters }))

    return finalFilteredList
  }

  useEffect(() => {
    if (props.sname === 'search') {
      /** THe user has requested to search for something */
      if (currentSearchString === '') {
        dispatch(updateTaskList({ sname: props.sname, list: [] }))
        dispatch(updateAllTaskList({ sname: props.sname, list: [] }))
        dispatch(updateTaskListLoaded({ sname: props.sname, isLoaded: false }))
      } else {
        initializeStepTaskList()
      }
    }
  }, [currentSearchString])

  const handleNewTasks = (list: FindTaskResults[]) => {
    console.log('TaskListConnectionHelper Handling:', list.length)
    if (!list) return

    dispatch(updateTaskListLoaded({ sname: props.sname, isLoaded: false }))
    dispatch(updateAllTaskList({ sname: props.sname, list: list }))

    // Commented out because we are filtering the list in the below useffect with allTaskList dependency
    /** All optional fitlers applied but still all the tasks */
    // const filteredList = filterTaskList(list)

    // dispatch(updateTaskList({ sname: props.sname, list: filteredList }))
    dispatch(updateTaskListLoaded({ sname: props.sname, isLoaded: true }))
  }

  /**
  Effect is called when the StepTaskList is changed.
  */
  const initializeStepTaskList = () => {
    if (currentStep && currentStep.current) {
      console.log('TaskListConnectionHelper Unsubscribe from previous')
      currentStep.current.unsubscribe()
      currentStep.current.unsubscribeStatus()
      /** Clear any previously loaded list of tasks because we have no active step subscribed */
      dispatch(updateTaskList({ sname: props.sname, list: [] }))
      dispatch(updateAllTaskList({ sname: props.sname, list: [] }))
      dispatch(updateTaskListLoaded({ sname: props.sname, isLoaded: false }))
    }

    currentStep.current = new StepTaskList({
      step_type: 'TaskList',
      step_option: props.step_option,
      text_en: props.step_text
    })

    currentStep.current.subscribeStatus((sname, status) => {
      console.log('TaskListConnectionHelper Status Update', sname, status)
      dispatch(updateTaskListStatus({ sname: props.sname, status: '(' + status + ')' }))
      if (status === 'Ready') {
        dispatch(updateTaskListLoaded({ sname: props.sname, isLoaded: true }))
      } else {
        dispatch(updateTaskListLoaded({ sname: props.sname, isLoaded: false }))
      }
    })

    console.log('TaskListConnectionHelper Loading Tasks, ', currentStep.current.sname, 'user=', currentUserName)

    const logic = new LogicEngine()
    currentStep.current.processStep(logic).then(() => {
      console.log('TaskListConnectionHelper Subscribing to StepTaskList', currentStep.current, 'user=', currentUserName)
      if (currentStep && currentStep.current) currentStep.current.subscribe(handleNewTasks)
    })
  }

  /** If the current username changes, we should remove the current step and
  wait for another one to be assigned */
  useEffect(() => {
    console.log('TaskListConnectionHelper Username Change to', currentUserName)

    if (currentUserName === '' || currentUserName === undefined) {
      /** Clear any previously loaded list of tasks because we have no active step subscribed */
      dispatch(updateTaskList({ sname: props.sname, list: [] }))
      dispatch(updateAllTaskList({ sname: props.sname, list: [] }))
      dispatch(updateTaskListLoaded({ sname: props.sname, isLoaded: false }))
      return
    }

    if (loadedUsername !== currentUserName) {
      dispatch(updateTaskListStatus({ sname: props.sname, status: '(Changing Users)' }))
      /** Clear any previously loaded list of tasks because we have no active step subscribed */
      setLoadedUsername(currentUserName)
      dispatch(updateTaskList({ sname: props.sname, list: [] }))
      dispatch(updateAllTaskList({ sname: props.sname, list: [] }))
      dispatch(updateTaskListLoaded({ sname: props.sname, isLoaded: false }))
      initializeStepTaskList()
    }
  }, [currentUserName])

  /** Initialize the connection helper because of a re-render which means the user may
  have changed or this is a fresh start without a user
  */
  useEffect(() => {
    // console.log('TaskListConnectionHelper Initialize, Username=', currentUserName)
    if (currentUserName === '') return
    // initializeStepTaskList()

    return () => {
      if (currentStep && currentStep.current) {
        console.log('TaskListConnectionHelper Unsubscribe from previous')
        currentStep.current.unsubscribeStatus()
        currentStep.current.unsubscribe()
      }
    }
  }, [])

  /** Update the list if any of the options change */
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(updateTaskListStatus({ sname: props.sname, status: '(Reloading)' }))
      dispatch(updateTaskListLoaded({ sname: props.sname, isLoaded: false }))

      /** All optional fitlers applied but still all the tasks */
      const filteredList = filterTaskList(allTaskList)
      dispatch(updateTaskList({ sname: props.sname, list: filteredList }))
      dispatch(updateTaskListLoaded({ sname: props.sname, isLoaded: true }))
      dispatch(updateTaskListStatus({ sname: props.sname, status: '(Ready)' }))
    }, 100)

    return () => clearTimeout(timer)
    /* added allTasklist dependency to avoid stale closure issue in filterTaskList()
    in this way, the filterTaskList() function will have the latest list and filters */
  }, [showMuted, showClosed, showRejected, showOther, allTaskList, currentRoute])

  /*
    const modifyMenuItems = (list: any): any => {
      // Update menu items
      const uniqueProjectIds = new Set(list.map((item: any) => item.project_id))
      return Array.from(uniqueProjectIds).map((projectId) => ({
        item_type: 'Link',
        subtitle_en: projectId,
        tooltip_en: projectId,
        route: location.pathname.includes('/page/dashboard')
          ? `/page/dashboard#type=${projectId}`
          : `/page/tasks#type=${projectId}`,
        view_groups: 'PI, PSI'
      }))
    }
  */

  return {} as TaskListConnectionHelperState
}
