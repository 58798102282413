import React from 'react'
import { Modal, Progress, Spinner } from 'reactstrap'

interface Props {
  width: number
  isOpen: boolean
  title?: string
}

const SyncModal = ({ width, isOpen, title = 'Syncing Data...' }: Props) => {
  return (
    <div>
      <Modal centered isOpen={isOpen} size='lg' className='sync-modal'>
        <div className='modal-body'>
          <h1 className='text-center mt-5' id='Syncing Data'>
            {title}
          </h1>
          <Spinner animation='border' role='status' className='d-block mx-auto mb-4' />
          <div>
            <Progress value={width} />
            <h5 className='text-center mt-3'>{width}%</h5>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default SyncModal
