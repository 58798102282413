import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { Spinner } from 'reactstrap'

import { CommonHelper, PSLHelper } from '@digitalworkflow/dwtranslateclient'
import { PSL } from '@digitalworkflow/psl_web'

import App from './App'
import { AuthProvider } from './context/AuthContext'
import { DataProvider } from './context/DataContext'
import { persistor, store } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'

const AppInitialize = () => {
  const [isPSLIntialize, setIsPSLIntialize] = useState<boolean>(true)

  useEffect(() => {
    Initialization()
  }, [])

  const Initialization = async () => {
    setIsPSLIntialize(true)

    // Default's to the staging server which will work
    // for all environments unless a firewall blocks staging

    if (process.env.REACT_APP_PSL_ENDPOINT) PSL.SetPSLEndpoint(process.env.REACT_APP_PSL_ENDPOINT)

    const envName = process.env.REACT_APP_ENV || process.env.REACT_APP_PSL_ENV || ''
    console.log('Initializing DWPortal with Environment: ', process.env.REACT_APP_PSL_ENV)

    PSL.SetProjectName('dw')
    PSL.SetEnvironment(envName)

    const envType = PSL.GetEnvironment()
    CommonHelper.setEnvironment(envType)

    await PSLHelper.initialize()
    setIsPSLIntialize(false)
  }

  return (
    <>
      {!isPSLIntialize ? (
        <AuthProvider>
          <DataProvider>
            <BrowserRouter
              future={{
                v7_relativeSplatPath: true,
                v7_startTransition: true
              }}
            >
              <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                  <App />
                </PersistGate>
              </Provider>
            </BrowserRouter>
          </DataProvider>
        </AuthProvider>
      ) : (
        <div className='d-flex justify-content-center'>
          <Spinner style={{ height: '30px', width: '30px' }} />
        </div>
      )}
    </>
  )
}
export default AppInitialize
