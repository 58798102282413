import React from 'react'
import { Modal } from 'reactstrap'

type ConfirmationCallback = (confirmed: boolean) => void

interface Props {
  isOpen: boolean
  handleConfirm: ConfirmationCallback
  title?: string
  description?: string
  confirmationButtonText?: string
  cancelButtonText?: string
}

const ConfirmCancelTask = (props: Props) => {
  const {
    title = 'Cancel Request',
    description = 'Please confirm that you would like to cancel the current request and return to the dashboard',
    confirmationButtonText = 'Yes, I would like to cancel',
    cancelButtonText = 'Close'
  } = props
  return (
    <div>
      <Modal centered isOpen={props.isOpen} size='lg' className='modal-dialog'>
        <div className='modal-header'>
          <h5 className='modal-title'>{title}</h5>
          <button
            type='button'
            className='close'
            data-dismiss='modal'
            aria-label='Close'
            onClick={() => props.handleConfirm(false)}
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <div className='modal-body'>
          <p>{description}</p>
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-primary'
            data-testid='confirm_yes'
            onClick={() => props.handleConfirm(true)}
          >
            {confirmationButtonText}
          </button>
          <button
            type='button'
            data-testid='confirm_no'
            className='btn btn-secondary'
            onClick={() => props.handleConfirm(false)}
            data-dismiss='modal'
          >
            {cancelButtonText}
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default ConfirmCancelTask
