type TaskListSettingsType = { [key: string]: { order: number; style: string } }

export const TaskListSettings: TaskListSettingsType = {
  Complete: {
    order: 60,
    style: 'Secondary'
  },
  'In Progress': {
    order: 30,
    style: 'Success'
  },
  'In Review': {
    order: 30,
    style: 'Success'
  },
  'Not Submitted': {
    order: 20,
    style: 'Primary'
  },
  'On Hold': {
    order: 10,
    style: 'Primary'
  },
  Processing: {
    order: 40,
    style: 'Info'
  },
  Rejected: {
    order: 50,
    style: 'Warning'
  },
  'Needs Attention': {
    order: 5,
    style: 'Danger'
  },
  'Client Feedback In-progress': {
    order: 30,
    style: 'Success'
  },
  Urgent: {
    order: 10,
    style: 'Warning'
  },
  Emergency: {
    order: 5,
    style: 'Danger'
  },
  Routine: {
    order: 30,
    style: 'Primary'
  },
  Review: {
    order: 30,
    style: 'Success'
  },
  Cancelled: {
    order: 65,
    style: 'Secondary'
  },
  Transferred: {
    order: 70,
    style: 'Secondary'
  },
  Sparks: {
    order: 70,
    style: 'Sparks-Task'
  }
}

/** Shortcut to get category config information even if cateogry is not configured  */
export const getTaskListSettings = (name: string | undefined) => {
  if (!name || !TaskListSettings[name])
    return {
      order: 100,
      style: 'Info'
    }
  return TaskListSettings[name]
}
