import { configureStore } from '@reduxjs/toolkit'
import settingsSlice from '../slices/settingsSlice'
import tasklistSlice from '../slices/tasklistSlice'
import localStorageMiddleware from '../slices/localStorageMiddleware'
import persistViewSettingsReducer from '../slices/taskViewSettingsSlice'
import { persistStore } from 'redux-persist'

export const store = configureStore({
  reducer: {
    tasks: tasklistSlice,
    settings: settingsSlice,
    taskViewSettings: persistViewSettingsReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(localStorageMiddleware)
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const persistor = persistStore(store)

export default store
