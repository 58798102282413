import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import './TaskListTable.scss'

// eslint-disable-next-line
import {
  CellClickedEvent,
  CellEditingStoppedEvent,
  ColDef,
  Column,
  ColumnApi,
  ExpandCollapseAllEvent,
  ColumnPivotModeChangedEvent,
  ColumnResizedEvent,
  FilterChangedEvent,
  GetContextMenuItemsParams,
  GridApi,
  IsGroupOpenByDefaultParams,
  MenuItemDef,
  RowClassParams,
  RowGroupOpenedEvent,
  ValueSetterParams,
  ColumnEvent
} from 'ag-grid-community'
import { LicenseManager } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import moment from 'moment'
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button, Spinner } from 'reactstrap'
import { isArray, isEqual } from 'lodash'

import {
  DataTools,
  FindTaskResults,
  LogicEngine,
  StringOptions,
  TaskCollection,
  TimeAgo
} from '@digitalworkflow/dwtranslateclient'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { LocalSettings } from '../../../utils/LocalSettings'
import { MuteTaskModal } from '../../MuteTaskModal'
import { checkIsUserAuthorized } from './TaskListHelper'
import { getTaskListSettings } from './TaskListSettings'
import { GroupRowInnerRenderer } from './TaskListTableRender'
import { TaskListTooltip } from './TaskListTooltip'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import {
  resetColumnFilterState,
  updateColumnGroupState,
  updateColumnResized,
  updateColumnState,
  updateExpandOrCollapseAll,
  updateFilterModel,
  updatePivotMode,
  updateRowGroupColumns
} from '../../../redux/slices/taskViewSettingsSlice'
import ConfirmCancelTask from '../../ConfirmModal/ConfirmCancelTask'

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-067139}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Brians_Com_LLC}_is_granted_a_{Multiple_Applications}_Developer_License_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{2}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{25_November_2025}____[v3]_[01]_MTc2NDAyODgwMDAwMA==138cede0283d1caca2b83e456c6fda4a'
)

export interface TaskListTableProps {
  items: FindTaskResults[]
  options: StringOptions
  onShowHistory?: (task_id: string) => void
  onShowTakeModel?: (task_id: string) => void
  onShowReassignModal?: (task_id: string) => void
  logicRef?: LogicEngine | undefined
  subShowingTask: boolean
  setSubShowingTask?: (task: FindTaskResults) => void
  renderTaskBadges: (task: FindTaskResults) => ReactElement
  taskLoading: boolean
  currentUserName: string
  resetColumnFilter?: boolean
  isSubTaskTable?: boolean
  setResetColumnFilter?: React.Dispatch<React.SetStateAction<boolean>>
}

export interface TaskListColumn {
  headerName: string
  field: string
  align: string
  type: string
  width?: number
}

const columns: TaskListColumn[] = [
  {
    headerName: 'Dash Category',
    field: 'dash_category',
    align: 'left',
    type: 'Text',
    width: 150
  },
  {
    headerName: 'Task Title',
    field: 'task_title',
    align: 'left',
    type: 'Text',
    width: 500
  },
  {
    headerName: 'Workflow State Name',
    field: 'workflow_name',
    align: 'left',
    type: 'Text',
    width: 150
  },
  {
    headerName: 'Create Date',
    field: 'create_dt',
    align: 'right',
    type: 'Date',
    width: 150
  },
  {
    headerName: 'Last Update',
    field: 'updated_dt',
    align: 'right',
    type: 'Date',
    width: 150
  },
  {
    headerName: 'Last Update by',
    field: 'updated_by',
    align: 'left',
    type: 'Text',
    width: 150
  },
  {
    headerName: 'Questions',
    field: 'open_query_count',
    align: 'left',
    type: 'Text',
    width: 150
  },
  {
    headerName: 'Actions',
    field: 'link',
    align: 'left',
    type: 'Action'
    // width: 60
  }
]

type TaskListAdditionalCol = { name: string; format: string }

const TaskListTable = ({
  items,
  options,
  onShowHistory,
  onShowReassignModal,
  setSubShowingTask,
  logicRef,
  renderTaskBadges,
  taskLoading,
  currentUserName,
  subShowingTask,
  onShowTakeModel,
  resetColumnFilter = false,
  setResetColumnFilter,
  isSubTaskTable
}: TaskListTableProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const gridRef = useRef<AgGridReact>(null)
  const isProgrammaticExpandCollapse = useRef(false)
  const [myItems, setMyItems] = React.useState<FindTaskResults[]>([])
  const [unmuteTask, setUnmuteTask] = useState<string>('')
  const [showMuteTaskModal, setShowMuteTaskModal] = useState<boolean>(false)
  const [selectedTask, setSelectedTask] = useState<FindTaskResults | undefined>(undefined)

  const [additionalColumns, setAdditionalColumns] = useState<TaskListAdditionalCol[]>([])
  /* Set to true to show a little busy sign when navigating */
  const [isNavigating, setIsNavigating] = React.useState(false)
  const [columnApi, setColumnApi] = useState<ColumnApi | null>(null)
  const [gridApi, setGridApi] = useState<GridApi | null>(null)
  const [firstDataRender, setFirstDataRender] = useState<boolean>(false)

  const tasksViewSetting = useSelector((state: RootState) => {
    if (options.checkOption('dashboard')) {
      return state.taskViewSettings.dashboard
    } else if (options.checkOption('Search')) {
      return state.taskViewSettings.search
    } else {
      return state.taskViewSettings.todo
    }
  })

  const getSname = () => {
    if (options.checkOption('dashboard')) {
      return 'dashboard'
    } else if (options.checkOption('Search')) {
      return 'search'
    } else {
      return 'todo'
    }
  }

  const projectName = useMemo(() => {
    // Get the current URL
    const url = new URL(window.location.href)
    // Extract the hash part (everything after #)
    const hash = url.hash.slice(1)
    const searchParams = new URLSearchParams(hash)
    return searchParams.get('type') ?? undefined
  }, [window.location.href])

  useEffect(() => {
    if (resetColumnFilter) {
      dispatch(resetColumnFilterState({ sname: getSname(), project: projectName }))
      resetAgGridFilters()
      adjustActionColumnWidth()
      if (setResetColumnFilter) setResetColumnFilter(false)
    }
  }, [resetColumnFilter])

  const resetAgGridFilters = () => {
    if (gridApi && columnApi) {
      gridApi.setFilterModel(null)
      columnApi.resetColumnState()
      columnApi.setRowGroupColumns(['dash_category'])
      columnApi.resetColumnGroupState()
      gridApi.setPivotMode(false)
      isProgrammaticExpandCollapse.current = true
      gridApi.expandAll()
    }
  }

  /** Helper function to copy the current task id to the clipboard */
  const onCopyTaskID = (task_id: string) => {
    navigator.clipboard
      .writeText(task_id)
      .then(() => {
        console.log('Task ID copied to clipboard')
      })
      .catch((err) => {
        console.error('Failed to copy task ID: ', err)
      })
  }

  //  parseHTMLString() will parse the HTML string and return the text content
  const parseHTMLString = (htmlString: string): string => {
    // Remove all HTML tags
    let text = htmlString.replace(/<\/?[^>]+(>|$)/g, ' ')

    // Decode HTML entities
    text = text
      .replace(/&nbsp;/g, ' ') // Replace non-breaking spaces
      .replace(/&amp;/g, '&') // Replace ampersand
      .replace(/&lt;/g, '<') // Replace less than
      .replace(/&gt;/g, '>') // Replace greater than
      .replace(/&quot;/g, '"') // Replace double quotes
      .replace(/&#39;/g, "'") // Replace single quotes
      .replace(/&apos;/g, "'") // Replace apostrophe

    // Trim extra spaces and return the clean text
    return text.replace(/\s+/g, ' ').trim()
  }

  const processGridData = useCallback(
    (gridData: FindTaskResults[]) => {
      return gridData.map((item: FindTaskResults) => {
        if (!item.data_fields || item?.data_fields?.length === 0) return item
        const additionalFields: Record<string, any> = {}
        const processedDataFields = item.data_fields.map((label) => {
          const colKey = label.label?.toString()?.trim()?.toLowerCase()
          const value =
            label.label === 'Last Updated' && label.value && typeof label.value === 'object'
              ? moment(label.value).format('MMMM Do YYYY, h:mm:ss')
              : label.boolValue && label.value && typeof label.value === 'string'
              ? parseHTMLString(label.value)
              : undefined
          if (label.boolValue && label.value && label.value !== 'undefined') {
            additionalFields[colKey] = value
          }
          return {
            ...label,
            value,
            boolValue: label.boolValue
          }
        })

        return {
          ...item,
          ...additionalFields, // Add additionalFields to the item
          data_fields: processedDataFields // Updated data_fields
        }
      })
    },
    [logicRef] // You can keep this as is if you're using it in your useCallback dependencies
  )

  useEffect(() => {
    const processItems = async () => {
      const gridData = items
        .filter((item) => item?.dash_category && item.dash_category.length > 0)
        .sort((a: FindTaskResults, b: FindTaskResults) => {
          return new Date(b.create_dt).getTime() - new Date(a.create_dt).getTime() // Sort in Ascending order for default case
        })
        .sort((a, b) => getTaskListSettings(a.dash_category).order - getTaskListSettings(b.dash_category).order)

      // if (!optionShowRejected) {
      //   gridData = gridData.filter((item) => item.dash_category !== 'Rejected' && item.dash_category !== 'Cancelled')
      // }
      // if (!optionShowMuted) {
      //   gridData = gridData.filter((item) => item.task_status !== 'Muted')
      // }

      const processedGridData: FindTaskResults[] = processGridData(gridData)

      const newAdditionalColumns: TaskListAdditionalCol[] = processedGridData.reduce(
        (acc: TaskListAdditionalCol[], item: FindTaskResults) => {
          if (item?.data_fields) {
            item.data_fields.forEach((field: any) => {
              // Check if the field is valid and not already included in the accumulator
              if (
                field?.label &&
                field.label !== '' &&
                field.value !== undefined &&
                field.value !== 'undefined' &&
                !acc.some((item) => item.name === field.label)
                // !acc.includes(field.label)
              ) {
                // Check if at least one item has a valid value for this field
                // const hasValidValue = processedGridData.some((entry) =>
                //   entry.data_fields?.some((f: any) => f.label === field.label && f.value !== undefined && f.value !== '')
                // )

                const hasValidValue = true

                if (hasValidValue) {
                  let display_format = DataTools.internalValidateString(field.display_format)
                  if (display_format === '') display_format = 'Text'

                  acc.push({
                    name: field.label,
                    format: display_format
                  })
                }
              }
            })
          }
          return acc
        },
        []
      )

      // Extract labels from the first object's data_fields to get the desired order
      const firstObjectKeys = processedGridData[0]?.data_fields?.map((field: any) => field.label)

      if (firstObjectKeys && Array.isArray(firstObjectKeys) && firstObjectKeys.length > 0) {
        // Sort newAdditionalColumns based on the order in firstObjectKeys
        const sortedAdditionalColumns = newAdditionalColumns.sort((a, b) => {
          const indexA = firstObjectKeys.indexOf(a.name)
          const indexB = firstObjectKeys.indexOf(b.name)

          // If the field exists in firstObjectKeys, sort by its index
          if (indexA !== -1 && indexB !== -1) {
            return indexA - indexB
          }

          // Fields not in firstObjectKeys will be placed at the end
          if (indexA === -1 && indexB === -1) {
            return 0 // Keep relative order if both are not in the firstObjectKeys
          }

          return indexA === -1 ? 1 : -1 // Place fields not in firstObjectKeys at the end
        })
        // Update the state with the sorted columns
        if (!isEqual(additionalColumns, sortedAdditionalColumns)) {
          setAdditionalColumns(sortedAdditionalColumns)
        }
      } else {
        if (!isEqual(additionalColumns, newAdditionalColumns)) {
          setAdditionalColumns(newAdditionalColumns)
        }
      }

      setMyItems(processedGridData)
      // reloadColumnState()
    }

    /** Don't allow updating too frequently by offsetting the update 100 ms into the
    future and clearing the timeout if we are called again really fast */
    const timer = setTimeout(() => {
      processItems()
    }, 100)

    return () => clearTimeout(timer)
  }, [items, getTaskListSettings, processGridData])

  useEffect(() => {
    adjustActionColumnWidth()
  }, [myItems, columnApi])

  const adjustActionColumnWidth = () => {
    if (columnApi) {
      const columnId = 'link'
      const width = myItems.some((item) => item.task_status === 'Muted') ? 200 : 120
      columnApi.setColumnWidth(columnId, width)
    }
  }

  // useEffect(() => {
  //   console.log('TaskListTable Change Items:', items)
  // }, [items])

  // useEffect(() => {
  //   console.log('TaskListTable Change getTaskListSettings:', getTaskListSettings)
  // }, [getTaskListSettings])

  // useEffect(() => {
  //   console.log('TaskListTable Change processGridData:', processGridData)
  // }, [processGridData])

  // useEffect(() =>
  // {
  //   if (gridRef?.current && gridRef?.current?.api)
  //   {
  //     gridRef.current.api.sizeColumnsToFit()
  //     gridRef.current.api.refreshCells()
  //   }
  // }, [additionalColumns])

  useEffect(() => {
    if (!isSubTaskTable) {
      reloadColumnFilterState()
    }
  }, [firstDataRender, getSname(), projectName, isSubTaskTable])

  useEffect(() => {
    if (isNavigating) {
      // Change cursor to busy
      document.body.style.cursor = 'wait'

      // Dim the screen
      const overlay = document.createElement('div')
      overlay.style.position = 'fixed'
      overlay.style.top = '0'
      overlay.style.left = '0'
      overlay.style.width = '100%'
      overlay.style.height = '100%'
      overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)'
      overlay.style.zIndex = '9999'
      document.body.appendChild(overlay)

      // Clean up function
      return () => {
        // Reset cursor
        document.body.style.cursor = 'default'

        // Remove overlay
        document.body.removeChild(overlay)
      }
    } else {
      return () => {}
    }
  }, [isNavigating])

  /* Reload column and filter state once data is completely loaded on ag-grid, its reload data based on sname and project(quote_tracker, propupdate, etc) */
  const reloadColumnFilterState = () => {
    if (!gridApi || !firstDataRender || !columnApi) return // Exit early if any dependency is missing

    const _projectName = projectName || 'default' // Default project name if not provided
    const projectSettings = tasksViewSetting[_projectName]

    if (!projectSettings) {
      resetAgGridFilters()
      return // Exit early if no settings found for the project
    }

    const {
      filterModel,
      columnState,
      columnGroupState,
      rowGroupColumns,
      columnResized,
      pivotMode,
      expandOrCollapseAll
    } = projectSettings.settings

    if (filterModel && gridApi.setFilterModel) gridApi.setFilterModel(filterModel)
    if (columnState && columnApi.applyColumnState) columnApi.applyColumnState({ state: columnState, applyOrder: true })
    if (columnGroupState?.length > 0 && columnApi.setColumnGroupState) columnApi.setColumnGroupState(columnGroupState)
    if (rowGroupColumns && isArray(rowGroupColumns)) columnApi.setRowGroupColumns(rowGroupColumns)
    if (expandOrCollapseAll) {
      if (expandOrCollapseAll === 'expandAll') gridApi.expandAll()
      else if (expandOrCollapseAll === 'collapseAll') gridApi.collapseAll()
    }

    /* Apply column resizing */
    if (columnResized?.forEach) columnResized.forEach((column) => columnApi.setColumnWidth(column.field, column.width))

    /* Set Pivot mode */
    gridApi.setPivotMode(pivotMode)

    /* reload agGridAutoColoumn pinned */
    if (columnState) {
      const agGridAutoColumn = columnState.find((item) => item.colId === 'ag-Grid-AutoColumn')
      if (agGridAutoColumn) {
        columnApi.setColumnPinned(agGridAutoColumn.colId, agGridAutoColumn.pinned)
      }
    }

    adjustActionColumnWidth()
  }

  const formatMutedUntil = (mutedUntilDt: string | Date | undefined): string => {
    if (!mutedUntilDt) return ''

    const now = moment()
    const mutedUntil = moment(mutedUntilDt)
    const duration = moment.duration(mutedUntil.diff(now))

    if (duration.asSeconds() <= 0) return ''

    const days = Math.floor(duration.asDays())
    const hours = duration.hours()
    const minutes = duration.minutes()

    if (days > 0) return `${days} ${days > 1 ? 'days' : 'day'}`
    if (hours > 0) return `${hours} ${hours > 1 ? 'hrs' : 'hr'}`
    if (minutes > 0) return `${minutes} ${minutes > 1 ? 'mins' : 'min'}`

    return '0 min'
  }

  const getColDateValue = (props: any, col: TaskListColumn) => {
    if (typeof props.data[col.field] === 'string') {
      if (props.data[col.field] === '') return ''
      return new Date(props.data[col.field]).toLocaleString(undefined, {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      })
      // .replace(',', '')
      // .replace(/\s+/g, ' ')
    }

    if (props.data[col.field]?.value) {
      return new Date(props.data[col.field].value).toDateString()
    }

    return props.data[col.field] || ''
  }

  /**
   * Grid cell renderer.
   *
   * @remarks
   * Used for rendering the grid cell values
   */
  const cellRenderFunc = (props: any, col: TaskListColumn) => {
    if (col.field === 'task_title') {
      if (props.data?.subtask_ids?.length || props.data?.subaction?.length) {
        return (
          <div className='d-flex justify-content-between task-title-container'>
            <div className='flex-grow-1 task-value'>{parseHTMLString(props.value)}</div>
            <div className='task-badge'>{renderTaskBadges(props.data)}</div>
          </div>
        )
      } else return parseHTMLString(props.value)
    }

    if (col.type === 'Timeago') {
      if (typeof props.data[col.field] === 'string') {
        if (props.data[col.field] === '') return ''
        return TimeAgo.inWords(new Date(props.data[col.field])).replace(' ago', '')
      }
      return props.data[col.field]?.value
        ? TimeAgo.inWords(new Date(props.data[col.field].value)).replace(' ago', '')
        : ''
    }

    if (col.type === 'Date') {
      return getColDateValue(props, col)
    }

    if (col.field === 'workflow_name' && options.checkOption('Todo')) {
      // Show one output on the task list and another on dashboards
      // This is the Task List for workers
      return (
        <>
          <FontAwesomeIcon icon={['fal', props.data.auser === '' ? 'circle' : 'circle-user']} className='me-2' />
          {props.value}
        </>
      )
    }

    if (col.type === 'Action') {
      return (
        <div className='d-flex gap-1'>
          {props?.data?.route && props?.data?.task_status !== 'Muted' && (
            <Button
              color='primary'
              size='sm'
              data-testid={'taskbutton_' + props.data.task_id}
              className='grid-link-btn'
              onClick={() => {
                setIsNavigating(true)
                navigate(props.data.route)
              }}
            >
              Open
            </Button>
          )}
          {props?.data?.task_status === 'Muted' && (
            <div>
              <Button
                color='primary'
                size='sm'
                className='grid-link-btn me-1'
                onClick={() => setUnmuteTask(props.data.task_id)}
              >
                Unmute
              </Button>

              <Button
                color='danger'
                size='sm'
                data-testid={'taskbutton_' + props.data.task_id}
                className='grid-link-btn'
                onClick={() => {}}
              >
                Muted {formatMutedUntil(props?.data?.muted_until_dt)}
              </Button>
            </div>
          )}
        </div>
      )
    }

    return props.value
  }

  const cellValueFunc = (props: any, col: TaskListColumn) => {
    if (!props.data) {
      return
    }

    if (col.type === 'Action') {
      return
    }

    // Commenting this because this code replaces original value with TimeAgo String, so resulting to sorting issues
    // if (col.type === 'Timeago') {
    //   if (typeof props.data[col.field] === 'string') {
    //     if (props.data[col.field] === '') return ''
    //     return TimeAgo.inWords(new Date(props.data[col.field])).replace(' ago', '')
    //   }
    // }

    // if (col.type === 'Date') {
    //   return props.data[col.field]?.value ? new Date(props.data[col.field].value).toDateString() : ''
    // }

    if (col.field === 'workflow_name' && options.checkOption('Dashboard')) {
      // Show one output on the task list and another on dashboards
      // This is the Dashboard for submitters
      return props.data.workflow_fullname
    }

    return props.data[col.field]
  }

  const cellValueSetterFunc = (params: ValueSetterParams) => {
    if (!params.newValue.trim().length) {
      return false
    }
    return true
  }

  const getAdditionalColumns = (cols: TaskListColumn[]): TaskListColumn[] => {
    const taskTitleIndex = cols.findIndex((col) => col.field === 'task_title')
    if (taskTitleIndex !== -1) {
      const newColumns: TaskListColumn[] = additionalColumns.map((item) => ({
        headerName: item.name,
        field: item.name.toString()?.trim()?.toLowerCase(),
        align: item.format === 'Date' ? 'right' : 'left',
        type: item.format
      }))
      return [...cols.slice(0, taskTitleIndex + 1), ...newColumns, ...cols.slice(taskTitleIndex + 1)]
    }
    return cols
  }

  const checkDashCategoryIsHide = () => {
    const _projectName = projectName || 'default' // Default project name if not provided
    const projectSettings = tasksViewSetting[_projectName]

    if (!projectSettings) {
      return true
    }
    const dashCategory = projectSettings.settings.columnState?.find((item) => item.colId === 'dash_category')
    return dashCategory?.hide ?? true
  }

  const getColumnDefs = (): ColDef[] => {
    return getAdditionalColumns(columns)?.map((col: TaskListColumn) => {
      const baseOptions: ColDef = {
        field: col.field,
        headerName: col.headerName,
        sortable: true,
        unSortIcon: false,
        resizable: true,
        flex: 1,
        minWidth: col.width || 150,
        filter: 'agTextColumnFilter',
        filterParams:
          col.field === 'task_title'
            ? {
                textMatcher: ({ filterOption, value, filterText, data }: any) => {
                  if (filterText == null) {
                    return false
                  }
                  const taskId = filterText.slice(5)
                  const index = value.lastIndexOf(filterText)
                  switch (filterOption) {
                    case 'contains':
                      return value.indexOf(filterText) >= 0 || data.task_id === taskId
                    case 'notContains':
                      return value.indexOf(filterText) < 0 || data.task_id === taskId
                    case 'equals':
                      return value === filterText || data.task_id === taskId
                    case 'notEqual':
                      return value !== filterText || data.task_id === taskId
                    case 'startsWith':
                      return value.indexOf(filterText) === 0 || data.task_id === taskId
                    case 'endsWith':
                      return (index >= 0 && index === value.length - filterText.length) || data.task_id === taskId
                    default:
                      // should never happen
                      console.warn('invalid filter type ', filterOption, value, filterText)
                      return false || data.task_id === taskId
                  }
                }
              }
            : col.type === 'Timeago'
            ? {
                filterOptions: ['contains', 'notContains', 'equals', 'notEqual'],
                textMatcher: ({ filterOption, value, filterText }: any) => {
                  if (filterText == null) {
                    return false
                  }
                  const renderedValue = TimeAgo.inWords(new Date(value)).replace(' ago', '')

                  switch (filterOption) {
                    case 'contains':
                      return renderedValue.toLowerCase().includes(filterText.toLowerCase())
                    case 'notContains':
                      return !renderedValue.toLowerCase().includes(filterText.toLowerCase())
                    case 'equals':
                      return renderedValue.toLowerCase() === filterText.toLowerCase()
                    case 'notEqual':
                      return renderedValue.toLowerCase() !== filterText.toLowerCase()
                    default:
                      return false
                  }
                }
              }
            : col.type === 'Date'
            ? {
                filterOptions: ['contains', 'notContains', 'equals', 'notEqual'],
                textMatcher: (props: any) => {
                  const { filterOption, filterText } = props
                  if (filterText == null) {
                    return false
                  }
                  const renderedValue = getColDateValue(props, col)

                  switch (filterOption) {
                    case 'contains':
                      return renderedValue.toLowerCase().includes(filterText.toLowerCase())
                    case 'notContains':
                      return !renderedValue.toLowerCase().includes(filterText.toLowerCase())
                    case 'equals':
                      return renderedValue.toLowerCase() === filterText.toLowerCase()
                    case 'notEqual':
                      return renderedValue.toLowerCase() !== filterText.toLowerCase()
                    default:
                      return false
                  }
                }
              }
            : undefined,
        rowGroup: false,
        hide: false,
        valueSetter: cellValueSetterFunc,
        valueGetter: (props) => cellValueFunc(props, col),
        valueFormatter: (props) => {
          if (col.type === 'Timeago' && props.value) {
            return TimeAgo.inWords(new Date(props.data[col.field])).replace(' ago', '')
          }
          return props.value
        },
        useValueFormatterForExport: true,
        cellStyle: {
          textAlign: col.align,
          display: 'flex',
          alignItems: 'center',
          justifyContent: col.align === 'right' ? 'flex-end' : col.align === 'center' ? 'center' : 'flex-start'
        },
        headerClass: 'ag-header-align-' + col.align,
        cellRenderer: (props: any) => {
          return cellRenderFunc(props, col)
        },
        floatingFilter: true,
        editable: col.field === 'task_title',
        enableRowGroup: true,
        tooltipComponent: TaskListTooltip,
        tooltipComponentParams: { color: '#ff00ff' },
        tooltipValueGetter: (params) => (col.type !== 'Action' ? params : undefined),
        headerTooltip: col.headerName,
        // 'Title: ' + params.data.task_title + '\n<br />' + 'Assigned to:' + params.data.auser
        tooltipField: col.type !== 'Action' ? 'task_title' : undefined,
        comparator: (valueA, valueB) => {
          if (typeof valueA === 'object' || typeof valueB === 'object') {
            // Custom comparison logic for objects
            if (valueA?.value?.getTime && valueB?.value?.getTime) {
              return valueA.value.getTime() - valueB.value.getTime()
            }
          }
          // Apply sorting on WorkOrder number column only
          if (col.field.toLowerCase() === 'wo num') {
            // Handle undefined and empty string cases
            if (!valueA && !valueB) return 0
            if (!valueA) return 1
            if (!valueB) return -1

            // Use localeCompare for proper alphanumeric sorting
            return -1 * valueA.localeCompare(valueB, undefined, { numeric: true, sensitivity: 'base' })
          }

          // Use the default comparison
          return valueA > valueB ? 1 : valueA < valueB ? -1 : 0
        }
      }

      if (col.type === 'Action') {
        baseOptions.pinned = 'right'
        baseOptions.resizable = false
        baseOptions.flex = 0
        baseOptions.tooltipComponent = null
        baseOptions.suppressMovable = true

        baseOptions.sortable = false
        baseOptions.minWidth = 120
        baseOptions.width = 120
        baseOptions.maxWidth = 120
        baseOptions.resizable = true
        /* Add valueGetter in action-column because need to export the value of action column etiher is mute or open */
        baseOptions.valueGetter = (params: any) => {
          if (!params.data) {
            return
          }
          return params.data?.task_status === 'Muted'
            ? `Muted ${formatMutedUntil(params?.data?.muted_until_dt)}`
            : 'Open'
        }
      }

      // if (col.width)       vvvvvv
      // {
      // baseOptions.width = col.width
      // baseOptions.width = col.width
      // baseOptions.resizable = false
      // }

      /** Set the sorting method for Dash Category to use the TaskSettings
    configuration from TaskListSettings.tsx */
      if (col.field === 'dash_category') {
        baseOptions.hide = checkDashCategoryIsHide()
        baseOptions.rowGroup = true
      }

      if (col.field === 'link') {
        baseOptions.sortable = false
        baseOptions.minWidth = 120
        baseOptions.width = 120
        baseOptions.maxWidth = 220
        baseOptions.resizable = false
      }

      return baseOptions
    })
  }

  const colDefs = useMemo<ColDef[]>(() => {
    return getColumnDefs()
  }, [additionalColumns])

  /** Determines if the row group is expanded by default */
  const isGroupOpenByDefault = (params: IsGroupOpenByDefaultParams<any, any>): boolean => {
    if (params.field === 'dash_category') {
      if (params.key === 'Complete') return false
      if (params.key === 'Rejected') return false
      const collapsedCategories = LocalSettings.taskListCollapsedCategories
      if (collapsedCategories.includes(params.key)) return false
    }
    return true
  }

  // const onFirstRender = (params: FirstDataRenderedEvent) =>
  // {
  //   console.log('onFirstRender Called')
  //   params.api.sizeColumnsToFit()
  // }

  /** Save the state of the columns in the grid view */
  const saveColumnState = () => {
    if (columnApi) {
      const columnState = columnApi.getColumnState()
      // Now we used redux store for storing the columnstate
      // localStorage.setItem('task_columnState', JSON.stringify(columnState))
      const columnGroupState = columnApi.getColumnGroupState()

      // localStorage.setItem('task_columnGroupState', JSON.stringify(columnGroupState))

      // console.log('STATE Save task_columnState', columnState)
      if (!subShowingTask) {
        dispatch(updateColumnState({ sname: getSname(), project: projectName, columnState: columnState }))
        dispatch(
          updateColumnGroupState({ sname: getSname(), project: projectName, columnGroupState: columnGroupState })
        )
      }
    }
  }

  /** Save the state of row configuration */
  const saveRowGroupState = () => {
    if (columnApi) {
      const rowGroupColumns = columnApi.getRowGroupColumns()
      const rowGroupState = rowGroupColumns.map((col) => col.getColId())
      // localStorage.setItem('task_rowGroupState', JSON.stringify(rowGroupState))
      // console.log('STATE Save task_rowGroupState')
      if (!subShowingTask) {
        dispatch(updateRowGroupColumns({ sname: getSname(), project: projectName, rowGroupColumns: rowGroupState }))
      }
    }
  }

  const onGridReady = async (params: any) => {
    setGridApi(params.api)
    setColumnApi(params.columnApi)
  }

  const onColumnChanged = (event: ColumnEvent) => {
    if (event.source === 'api' || event.source === 'gridOptionsChanged') return
    saveColumnState()
  }

  const onRowGroupChanged = (event: any) => {
    if (event.source === 'api' || event.source === 'gridOptionsChanged') return
    saveRowGroupState()
  }

  /* I commented this because we are no longer use localstorage for storing and retreiving column state, using redux store for that */
  // const reloadColumnState = () => {
  //   if (gridApi && columnApi) {
  //     // Load saved states on component mount
  //     const savedColumnState = localStorage.getItem('task_columnState')
  //     const savedRowGroupState = localStorage.getItem('task_rowGroupState')
  //     const savedGroupState = localStorage.getItem('task_columnGroupState')

  //     // console.log('STATE Get task_columnState', savedColumnState)
  //     // console.log('STATE Get task_rowGroupState', savedRowGroupState)

  //     if (savedColumnState) {
  //       try {
  //         const columnState = JSON.parse(savedColumnState)

  //         columnApi.applyColumnState({
  //           state: columnState,
  //           applyOrder: true
  //         })
  //       } catch (error) {
  //         console.error('Error applying saved column state:', error)
  //       }
  //     }

  //     if (savedGroupState) {
  //       try {
  //         columnApi.setColumnGroupState(JSON.parse(savedGroupState))
  //       } catch (e) {
  //         columnApi.resetColumnGroupState()
  //       }
  //     }

  //     if (savedRowGroupState) {
  //       const rowGroupColumns = JSON.parse(savedRowGroupState)
  //       columnApi.setRowGroupColumns(rowGroupColumns)
  //     }
  //   }
  // }

  // useEffect(() => {
  // reloadColumnState()
  // }, [gridApi, columnApi])

  // /** On screen width change, readjust the grid columns width */
  // const onGridViewportResize = (params: GridSizeChangedEvent) =>
  // {
  //   // resizeGridColumns(params)
  //   params.api.sizeColumnsToFit()
  // }

  const onRowGroupOpened = (params: RowGroupOpenedEvent) => {
    const category = params.node.key
    const oldCollapsedCategories = LocalSettings.taskListCollapsedCategories
    if (!category) return
    if (params.expanded && oldCollapsedCategories.includes(category)) {
      LocalSettings.taskListCollapsedCategories = oldCollapsedCategories.filter((c) => c !== category)
    }
    if (!params.expanded && !oldCollapsedCategories.includes(category)) {
      LocalSettings.taskListCollapsedCategories = [...oldCollapsedCategories, category]
    }
  }

  /** On screen width change, readjust the grid columns width */
  const onCellEditingStopped = async (params: CellEditingStoppedEvent) => {
    if (params.valueChanged) {
      console.log('Task title changed for ', params.data.task_id, ': ', params.newValue)
      if (!params.newValue.trim().length) {
        return
      }
      const task = await TaskCollection.findTaskById(params.data.task_id)
      if (task) {
        console.log('Renaming task title:', task)
        task.setTaskTitle(params.newValue)
        task.save()
      }
    }
  }

  const getRowClass = (params: RowClassParams): string => {
    if (params && params.data) {
      const settings = getTaskListSettings(params.data.dash_category)
      return 'tasklist-' + settings.style
    }

    return ''
  }

  /** Return the context menu for the rows in the grid */
  const getContextMenuItems = (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
    const result: (string | MenuItemDef)[] = []
    console.log('GetContextMenuCalled')
    const data = params.node?.data

    // {(contextTask.auser !== '' || contextTask.auser !== currentUserName) &&
    //   contextTask.task_status !== 'Closed' &&
    //   !isDashboard() && <li onClick={() => onShowTakeModel(contextTask.task_id)}>Take</li>}
    if (
      !subShowingTask &&
      data?.auser !== currentUserName &&
      data.task_status !== 'Closed' &&
      data.task_status !== 'Rejected' &&
      data.task_status !== 'Cancelled' &&
      (options.checkOption('Todo') || options.checkOption('Search'))
    ) {
      result.push({
        name: 'Take',
        action: () => {
          if (onShowTakeModel && data) onShowTakeModel(data.task_id)
        }
      })
    }

    if (params.node && params.node.data && params.node.data.task_id && onShowHistory) {
      const task_id = params.node.data.task_id
      result.push({
        name: 'History of task ' + task_id,
        action: () => {
          console.log('Clicked history of task', task_id)
          onShowHistory(task_id)
        }
      })

      const colId = params.column?.getColId()
      const rowIndex = params.node?.rowIndex
      if (colId && rowIndex) {
        result.push({
          name: 'Rename task ' + task_id,
          action: () => {
            console.log('Clicked rename task', task_id, params)
            params.api.startEditingCell({
              rowIndex: rowIndex,
              colKey: colId
            })
          }
        })
      }
    }

    if (
      params.node &&
      params.node.data &&
      params.node.data.task_id &&
      params.node.data.can_manage &&
      onShowReassignModal
    ) {
      const task_id = params.node.data.task_id
      result.push({
        name: 'Reassign task ' + task_id,
        action: () => {
          console.log('Clicked reassign task', task_id)
          onShowReassignModal(task_id)
        }
      })
    }

    if (
      params.node &&
      params.node.data &&
      params.node.data.task_id &&
      params.node.data.task_status === 'InProgress' &&
      checkIsUserAuthorized(params.node.data.auser, params.node.data.create_by, params.node.data.can_manage)
    ) {
      const task_id = params.node.data.task_id
      setSelectedTask(params.node.data)
      result.push({
        name: 'Mute task ' + task_id,
        action: () => {
          console.log('Clicked mute task', task_id)
          setShowMuteTaskModal(true)
        }
      })
    }

    if (params.node?.data.task_status === 'Muted') {
      const task_id = params.node.data.task_id
      result.push({
        name: 'Unmute task  ' + task_id,
        action: () => {
          setUnmuteTask(task_id)
        }
      })
    }

    if (result.length > 0) result.push('separator')

    if (params.node && params.node.data && params.node.data.task_id) {
      const task_id = params.node.data.task_id
      result.push({
        name: 'Copy Task ID: ' + task_id,
        action: () => {
          console.log('Clicked copy task id', task_id)
          onCopyTaskID(task_id)
        }
      })
    }

    if (params.column) {
      result.push('copy')
      result.push('copyWithHeaders')
      result.push('separator')
      result.push('export')
    }

    console.log('node=', params.node)

    return result
  }

  const onCellClicked = (params: CellClickedEvent) => {
    // If the task is muted, don't do anything
    if (params.data.task_status === 'Muted') return

    if (params.colDef.field === 'link') {
      // Don't do any action when we click on Action column
      return
    }
    if (setSubShowingTask && params.data?.subtask_ids?.length) {
      setSubShowingTask(params.data)
    }
  }

  const handleCloseModal = () => setShowMuteTaskModal(false)
  const handleMuteTask = async (muteDuration: number, comment?: string) => {
    if (selectedTask?.task_id) {
      const task = await TaskCollection.findTaskById(selectedTask?.task_id)
      const muteResponse = await task?.SetMute(true, muteDuration, comment)
      if (muteResponse?.success) {
        toast.success(muteResponse?.message)
      } else toast.error(muteResponse?.message)
    }
  }

  const handleUnMuteTask = async (taskId: string) => {
    const task = await TaskCollection.findTaskById(taskId)
    const muteResponse = await task?.SetMute(false)
    if (muteResponse?.success) {
      toast.success(muteResponse?.message)
    } else toast.error(muteResponse?.message)
  }

  const getAllDashCategories = () => {
    return Array.from(
      new Set(
        myItems.filter((item) => item?.dash_category && item.dash_category.length > 0).map((item) => item.dash_category)
      )
    ).filter((category): category is string => typeof category === 'string')
  }

  const onExpandOrCollapseAll = (event: ExpandCollapseAllEvent) => {
    if (event.source === 'expandAll') {
      LocalSettings.taskListCollapsedCategories = []
    } else {
      LocalSettings.taskListCollapsedCategories = getAllDashCategories()
    }
    if (!subShowingTask && !isProgrammaticExpandCollapse.current) {
      dispatch(
        updateExpandOrCollapseAll({ sname: getSname(), project: projectName, expandOrCollapseAll: event.source })
      )
    } else {
      isProgrammaticExpandCollapse.current = false
    }
  }

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel'
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel'
        }
      ],
      defaultToolPanel: ''
    }
  }, [])

  const onFilterChanged = (e: FilterChangedEvent) => {
    if (!subShowingTask) {
      dispatch(updateFilterModel({ sname: getSname(), project: projectName, filterModel: e.api.getFilterModel() }))
    }
  }

  const onFirstRender = () => {
    setFirstDataRender(true)
  }

  const onColumnResized = (event: ColumnResizedEvent) => {
    if (event.source === 'flex' || event.source === 'api') return
    if (event.columns && firstDataRender) {
      const _columns = event.columns.map((col: Column) => {
        return {
          field: col.getColId(),
          width: col.getActualWidth()
        }
      })
      if (!subShowingTask) {
        dispatch(updateColumnResized({ sname: getSname(), project: projectName, columnResized: _columns }))
      }
    }
  }

  const onColumnPivotChanged = (event: ColumnPivotModeChangedEvent) => {
    if (!subShowingTask) {
      dispatch(updatePivotMode({ sname: getSname(), project: projectName, pivotMode: event.columnApi.isPivotMode() }))
    }
  }

  return (
    <div className='task-list-table'>
      <div className='ag-theme-balham grid-table'>
        {taskLoading && (
          <div className='loading-overlay'>
            <Spinner color='primary'></Spinner>
          </div>
        )}
        <AgGridReact<any>
          ref={gridRef}
          columnDefs={colDefs}
          suppressLoadingOverlay={taskLoading}
          // overlayNoRowsTemplate={`<span>${taskLoading ? 'Loading...' : ''}</span>`}
          onFirstDataRendered={onFirstRender}
          onColumnResized={onColumnResized}
          rowSelection='multiple'
          enableRangeSelection
          rowData={myItems}
          pagination={false}
          onColumnPivotModeChanged={onColumnPivotChanged}
          getRowClass={getRowClass}
          onGridReady={onGridReady}
          onExpandOrCollapseAll={onExpandOrCollapseAll}
          onFilterChanged={onFilterChanged}
          onSortChanged={onColumnChanged}
          onColumnVisible={onColumnChanged}
          onColumnPinned={onColumnChanged}
          onColumnMoved={onColumnChanged}
          onColumnRowGroupChanged={onRowGroupChanged}
          // onGridSizeChanged={onGridViewportResize}
          isGroupOpenByDefault={isGroupOpenByDefault}
          /** Group Row Related Settings */
          groupDisplayType='groupRows'
          groupMaintainOrder
          groupRowRendererParams={{
            innerRenderer: GroupRowInnerRenderer
          }}
          onRowGroupOpened={onRowGroupOpened}
          getContextMenuItems={getContextMenuItems}
          onCellEditingStopped={onCellEditingStopped}
          tooltipShowDelay={1}
          onCellClicked={onCellClicked}
          sideBar={sideBar}
        />
      </div>
      <MuteTaskModal
        showMuteTaskModal={showMuteTaskModal}
        onCloseModal={handleCloseModal}
        handleMuteTask={handleMuteTask}
      />
      <ConfirmCancelTask
        isOpen={unmuteTask.length > 0}
        handleConfirm={(value) => {
          if (value) {
            handleUnMuteTask(unmuteTask)
          }
          setUnmuteTask('')
        }}
        description='Are you sure you want to unmute this task? '
        title='Unmute task'
        confirmationButtonText='Yes'
        cancelButtonText='No'
      />
    </div>
  )
}

export default TaskListTable
