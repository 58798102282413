import { AuthService } from '@digitalworkflow/dwloginclient'
import { MenuItem, ProfileMenuItem } from '@digitalworkflow/dwreactcommon'

import { LocalSettings } from '../utils/LocalSettings'
import { PSLHelper } from '@digitalworkflow/dwtranslateclient'
import { PSL } from '@digitalworkflow/psl_web'
import { clearDatabase } from '../utils/syncDatabase'

const authService = AuthService.instance()

/** Return true if the  */
export const getIORPS = () => {
  if (window.location.hostname?.indexOf('rps') !== -1) return true
  if (window.location.hostname?.startsWith('io')) return true
  if (window.location.hash && window.location.hash.indexOf('rps') !== -1) return true
  if (window.location.pathname.startsWith('/io/')) return true
  return false
}

export const getLoginPortalID = () => {
  /** IO RPS / Digital Workflow */
  if (getIORPS()) return PSLHelper.IORPSLoginPortalId()
  /** Digital Workflow Normal */
  return PSLHelper.DWLoginPortalId()
}

export const redirectToLogin = () => {
  console.log('Redirecting to Login Portal')
  authService.authLogout()
  LocalSettings.removePortalAuthToken()
  LocalSettings.removePortalUser()
  window.location.href = `${PSLHelper.LoginPortalUrl()}/login/${getLoginPortalID()}?return=` + window.location.hostname
}

export const projectName = 'dwportal'
export const autoCloseMenuText = 'Auto close menu'

export const menuItems: Array<MenuItem> = PSL.ProdRules()
  ? [
      {
        item_type: 'Label',
        title_en: 'MY WORKFLOW',
        view_groups: null
      },
      {
        item_type: 'Link',
        title_en: 'Dashboard',
        icon: 'fa-light fa-table-columns',
        view_groups: 'PI, PSI',
        route: '/page/dashboard',
        children: []
      },
      {
        item_type: 'Link',
        title_en: 'Task Inbox',
        icon: 'fa-light fa-arrow-down-to-arc',
        view_groups: 'PI, PSI',
        route: '/page/tasks',
        children: []
      },
      {
        item_type: 'Label',
        title_en: 'MODULES',
        view_groups: null
      },
      {
        item_type: 'Link',
        title_en: 'Digital Forms',
        icon: 'fa-solid fa-book-atlas',
        view_groups: 'PI, PSI',
        route: '/page/digital_forms',
        children: []
      },
      {
        item_type: 'Link',
        title_en: 'Force Update',
        icon: 'fa fa-refresh',
        view_groups: 'PI, PSI',
        route: '/force-update',
        children: []
      },
      {
        item_type: 'Link',
        title_en: 'System Access',
        icon: 'fa fa-user',
        view_groups: 'PI, PSI',
        route: '/test-accounts',
        children: []
      }
    ]
  : [
      {
        item_type: 'Label',
        title_en: 'MY WORKFLOW',
        view_groups: null
      },
      {
        item_type: 'Link',
        title_en: 'Dashboard',
        icon: 'fa-light fa-table-columns',
        view_groups: 'PI, PSI',
        route: '/page/dashboard',
        children: []
      },
      {
        item_type: 'Link',
        title_en: 'Task Inbox',
        icon: 'fa-light fa-arrow-down-to-arc',
        view_groups: 'PI, PSI',
        route: '/page/tasks',
        children: []
      },
      {
        item_type: 'Label',
        title_en: 'MODULES',
        view_groups: null
      },
      {
        item_type: 'Link',
        title_en: 'Digital Forms',
        icon: 'fa-solid fa-book-atlas',
        view_groups: 'PI, PSI',
        route: '/page/digital_forms',
        children: []
      },
      {
        item_type: 'Link',
        title_en: 'Force Update',
        icon: 'fa fa-refresh',
        view_groups: 'PI, PSI',
        route: '/force-update',
        children: []
      },
      {
        item_type: 'Link',
        title_en: 'Test Accounts',
        icon: 'fa fa-user',
        view_groups: 'PI, PSI',
        route: '/test-accounts',
        children: []
      },
      {
        item_type: 'Link',
        title_en: 'Settings',
        icon: 'fa fa-cog',
        view_groups: 'PI, PSI',
        route: 'page/settings',
        children: []
      }
      // {
      //   item_type: 'Link',
      //   title_en: 'Lookup Manager',
      //   icon: 'fa-solid fa-list-check',
      //   view_groups: 'PI, PSI',
      //   route: '/lookup-manager',
      //   children: []
      // }
    ]

export const profileMenuItems: Array<ProfileMenuItem> = [
  {
    type: 'Action',
    text_en: autoCloseMenuText
  },
  {
    type: 'Action',
    text_en: 'Logout',
    icon: 'fa fa-power-off',
    action: async () => {
      try {
        const isDataBaseCleared = await clearDatabase()
        if (isDataBaseCleared) {
          console.log('src/constants/index.ts profileMenuItems Database Cleared')
          await authService.authLogout()
          LocalSettings.lastLoginUserEmail = JSON.parse(LocalSettings.getPortalUser() ?? '')?.email
          LocalSettings.removePortalUser()
          LocalSettings.removePortalAuthToken()
          window.location.href = `${PSLHelper.LoginPortalUrl()}/login/${getLoginPortalID()}${
            window.location.host.match(/local/) ? '?return=local' : ''
          }`
        }
      } catch (error) {
        console.log('src/constants/index.ts profileMenuItems error', error)
      }
    }
  }
]

export const keyMap: Record<string, string> = {
  F1: '/page/dashboard',
  F2: '/page/tasks'
}
